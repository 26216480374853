<template>
  <section class="main-section house-section">
    <div class="house-section-1">
      <div class="font-0"><img :src="ossPath + 'house-1.png'" class="full-img" /></div>
      <div class="house-desginer">意大利设计师   Mr. Gennaro</div>
      <div class="house-1-p">
        <p>很高兴为大家介绍MELODIOUS LIVING悠扬的生活 （旋律优美，创造节奏），首个品牌MAIER HOME顶层公寓豪华生活。</p>
      </div>
    </div>
    <div class="house-layout">
      <div class="house-title">人如其居</div>
      <p>独特的顶层公寓的设计仅供专属客户使用。</p><br/>
      <p>最先进的硬件和电子设备，为经理人提供最佳服务。</p><br/>
      <p>270°全景视野，只为最挑剔的人提供无与伦比的生活。</p><br/>
      <div class="d-flex">
        <div class="house-2-img font-0"><img :src="ossPath + 'house-2-1.png'" class="full-img"></div>
        <div class="house-2-img font-0"><img :src="ossPath + 'house-2-2.png'" class="full-img"></div>
        <div class="house-2-img font-0"><img :src="ossPath + 'house-2-3.png'" class="full-img"></div>
      </div>
      <div class="house-title">更多你所期待的</div>
      <p>配备 Poliform、Longhi、Fendi、Giorgetti 和 Bang & Olufsen 的卓越内饰和雅致的固定装置。</p><br/>
      <p>配件采用原装大理石和镶木地板，以及德国唯宝、汉斯格雅等世界知名品牌卫浴。</p><br/>
      <p>厨房和橱柜采用 Gaggenau、Miele 和 Blanco 的电器及配件。</p><br/>
    </div>
    <div class="house-3">
      <div class="font-0"><img :src="ossPath + 'house-3.png'" class="full-img"  /></div>
    </div>
    <div class="house-layout">
      <p>入口具有多功能空间</p>
      <div class="house-4-img font-0"><img :src="ossPath + 'house-4.png'" class="full-img"></div>
      <p>空间设计的强烈平衡以及创新材料和主饰面的结合为公寓提供了一个技术性但实用的豪华住所。</p>
      <div class="house-5-img font-0"><img :src="ossPath + 'house-5.png'" class="full-img"></div>
    </div>
    <div class="house-5 d-flex">
      <div class="house-5-left font-0"><img :src="ossPath + 'house-5-1.png'" class="full-img"></div>
      <div class="house-5-right font-0"><img :src="ossPath + 'house-5-2.png'" class="full-img"></div>
    </div>
    <div class="house-layout">
      <p>MAIER KITCHEN 采用新材料和饰面进行定制，丰富的拉丝铜将木材和大理石材料结合在一起，营造出令人兴奋的自然感觉。</p>
      <div class="house-6-img font-0"><img :src="ossPath + 'house-6.png'" class="full-img"></div>
      <div class="house-6-div">
        <div class="house-6-1 font-0"><img :src="ossPath + 'house-6-1.png'" class="full-img"></div>
        <div class="house-6-2 font-0"><img :src="ossPath + 'house-6-2.png'" class="full-img"></div>
      </div>
      <p>丰富的玻璃元素和推拉门连接到MAIER书架。</p>
      <div class="house-7 font-0"><img :src="ossPath + 'house-7.png'" class="full-img"></div>
      <div class="house-8 font-0"><img :src="ossPath + 'house-8.png'" class="full-img"></div>
      <p>欢迎客人的区域，可以在冥想和放松的同时欣赏精美的葡萄酒和烈酒。</p>
      <div class="house-9 font-0"><img :src="ossPath + 'house-9.png'" class="full-img"></div>
      <div class="house-10 font-0"><img :src="ossPath + 'house-10.png'" class="full-img"></div>
      <p>木板的温暖被带到主卧室，通过几何形状悠扬地融合在一起。</p>
      <div class="house-11 font-0">
        <img :src="ossPath + 'house-11.png'" class="full-img">
        <p>胡桃色意大利红木，古铜色结构和皮革软垫抽屉，为业主的提供了一个舒适宽敞的空间。</p>
      </div>
      <div class="house-12 font-0">
        <img :src="ossPath + 'house-12.png'" class="full-img">
        <div class="house-12-text">
          <img :src="ossPath + 'house-bg-5.png'">
        </div>
      </div>
      <p>洗漱水槽、可抽出式铁板和可移动搁板，增加空间灵活性。</p>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
